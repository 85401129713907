import 'intersection-observer';
import scrollama from 'scrollama';
import $ from 'jquery';

function homepageVideo() {
    // instantiate the scrollama
    const scroller = scrollama();

    // setup the instance, pass callback functions
    scroller
        .setup({
            step: '.media-panel',
            offset: 0.5
        })
        .onStepEnter(handleEnter);

    // Play video
    function handleEnter(el) {
        let $video = $(el.element).find('video');
        pauseAllVideos();
        $video[0] ? $video[0].play() : null;
    }

    function pauseAllVideos(){
        const $allVideos = Array.from($('video'));
        $allVideos.forEach(video => video.pause());
    }
}

function smoothScroll(){
    $('.media-panel__arrow').on('click', function(e){
        e.preventDefault();

        $('html, body').animate({
            scrollTop: $(this.hash).offset().top
        }, 450, () => {
            // add hash to URL when done scrolling
            window.location.hash = this.hash;
        });
    });
}

export default () => {
    $(window).on('load', () => {
        if($('.media-panel').length) {
            homepageVideo();
            smoothScroll();
        }
    });
};
