import $ from 'jquery';

export default () => {
    $(window).on('load', () => {
        // show link after scrolling past it
        if($('.js-top-link').length) {
            const $pageNav = $('.js-top-link');
            const elementOffset = $pageNav.offset().top;
            const fixedClass = 'is-fixed';

            $(document).on('scroll', () => {
                if ($(document).scrollTop() >= elementOffset) {
                    $pageNav.addClass(fixedClass);
                } else {
                    $pageNav.removeClass(fixedClass);
                }
            });
        }

        // take user back to top
        $('.js-top-link').on('click', function() {
            $('html, body').animate({
                scrollTop: 0
            }, 300);
        });
    });
};
