// prevent videos from being loaded under 1024px
export default () => {
    let allVideos = [];

    if (window.matchMedia('(min-width: 1024px)').matches) {
        allVideos = Array.prototype.slice.call(
            document.querySelectorAll(
                '.media-panel__video, .video-hero__video, .block__media'
            )
        );
    } else {
        allVideos = Array.prototype.slice.call(
            document.querySelectorAll(
                '.block__media'
            )
        );
    }
    if (allVideos){
        allVideos.forEach((video) => {
            const videoSource = video.children[0];
            videoSource.setAttribute('src', videoSource.dataset.src);
            video.load();
        });
    }
};
