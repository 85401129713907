import 'intersection-observer';
import scrollama from 'scrollama';
import $ from 'jquery';

function scrollamaInit() {
    // instantiate the scrollama
    const scroller = scrollama();

    // define selectors and classes
    const $pageNavLinks = $('.page-nav__link');
    const activeClass = 'is-active';

    // setup the instance, pass callback functions
    scroller
        .setup({
            step: '.js-nav-trigger',
            offset: 0.14
        })
        .onStepEnter(handleEnter)
        .onStepExit(handleExit);

    function handleEnter(e) {
        // strip the menu active classes
        $pageNavLinks.removeClass(activeClass);

        // add active class
        const match = $(`a[href='#${e.element.id}']`)[0];
        $(match).addClass(activeClass);
    }

    function handleExit(el) {
        // update the classes if we scroll in to a previous section
        if (el.direction == 'up') {
            const currentActiveLink = $('.page-nav__link.is-active')[0];
            $(currentActiveLink).removeClass(activeClass);

            // don't add class to the back to top link
            if (el.index !== 0) {
                const prevSiblingLink = $(currentActiveLink).prev()[0];
                $(prevSiblingLink).addClass(activeClass);
            }
        }
    }
}

export default () => {
    $(window).on('load', () => {
        if($('.page-nav').length) {
            // define selectors and classes
            const $pageNav = $('.page-nav');
            const elementOffset = $pageNav.offset().top;
            const fixedClass = 'is-fixed';

            // fix the nav on scroll
            $(document).on('scroll', () => {
                if ($(document).scrollTop() >= elementOffset) {
                    $pageNav.addClass(fixedClass);
                } else {
                    $pageNav.removeClass(fixedClass);
                }
            });

            scrollamaInit();
        }
    });
};
