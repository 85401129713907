import $ from 'jquery';
import { throttle } from 'lodash';
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';

const DESKTOP = 'desktop';
const MOBILE = 'mobile';
const SHOW = 'show';
const HIDE = 'hide';

class HeaderUI {
    constructor(node) {
        this.$node = $(node);

        // Body
        this.$body = $('body');
        this.bodyNoScrollClass = 'no-scroll';

        // Different behavior depending on if this is the home page
        this.isHomePage = this.$body.hasClass('template-home-page');

        // Icons/triggers
        this.$searchTrigger = $('.js-search-toggle');
        this.$menuTrigger = $('.js-mobile-menu-toggle');
        this.$overlayTriggerDesktop = $('#apply-overlay-btn-desktop');
        this.$overlayTriggerMobile = $('#apply-overlay-btn-mobile');

        this.iconActiveClass = 'active';

        // Search panel
        this.$searchPanel = $('.search-panel');
        this.$searchPanelInput = $('.search-panel__input');
        this.searchPanelActiveClass = 'search-panel--active';

        // Apply Overlay
        this.$applyOverlay = $('.apply-overlay');
        this.applyOverlayActiveClass = 'apply-overlay--active';

        // Mobile menu
        this.$mobileMenu = $('.header__menus--mobile');
        this.mobileMenuActiveClass = 'is-visible';

        // Header CTA
        this.$headerCTA = $('.header-cta');
        this.headerCTAfixedClass = 'header-cta--fixed';
        this.headerCTAhiddenClass = 'header-cta--hidden';

        // Header mobile CTA
        this.$headerCTAMobile = $('.header-cta-mobile');
        this.headerCTAMobileVisibleClass = 'header-cta-mobile--visible';

        // Header
        this.$header = $('.header');
        this.headerHeight = this.$header.height();

        // Header Links
        this.$headerLinks = $('.nav__link-primary');
        this.headerLinksHiddenClass = 'nav__link--hidden';

        // Initialize CTA top
        this.$headerCTA.css('top', this.headerHeight);

        // Distance to scroll before fixing the CTA
        this.scrollAmount = this.headerHeight;

        if (this.isHomePage) {
            this.$body.css('padding-top', this.headerHeight);
        }

        // State
        this.menuState = {
            menu: {
                open: false,
            },
        };

        this.searchState = {
            menu: {
                open: false,
            },
        };

        this.overlayState = {
            menu: {
                open: false,
            },
        };

        this.bindEvents();
    }

    // Toggle view for CTA links
    ctaToggle(view, action) {
        if (view == MOBILE) {
            if (action == SHOW) {
                this.$headerCTAMobile.addClass(
                    this.headerCTAMobileVisibleClass
                );
            } else if (action == HIDE) {
                this.$headerCTAMobile.removeClass(
                    this.headerCTAMobileVisibleClass
                );
            }
        } else if (view == DESKTOP) {
            if (action == SHOW) {
                this.$headerCTA.removeClass(this.headerCTAhiddenClass);
            } else if (action == HIDE) {
                this.$headerCTA.addClass(this.headerCTAhiddenClass);
            }
        }
    }

    // Toggles display of header icons. If active, an X appears.
    activeToggle(elm, active) {
        if (active) {
            elm.addClass(this.iconActiveClass);
        } else {
            elm.removeClass(this.iconActiveClass);
        }
    }

    visibilityToggle(elm, visible){
        if (visible) {
            elm.css('visibility', 'visible');
        } else {
            elm.css('visibility', 'hidden');
        }
    }

    // Toggles display of header links. If hidden, visibility is set to hidden.
    linksToggle(hidden) {
        if (hidden) {
            this.$headerLinks.addClass(this.headerLinksHiddenClass);
        } else {
            this.$headerLinks.removeClass(this.headerLinksHiddenClass);
        }
    }

    // Toggles no scroll of body and header UI element
    noScrollToggle(active, elm) {
        if (active) {
            if (elm) disableBodyScroll(elm);
            setTimeout(() => {
                this.$body.addClass(this.bodyNoScrollClass);
            }, 10);
        } else {
            if (elm) enableBodyScroll(elm);
            this.$body.removeClass(this.bodyNoScrollClass);
        }
    }

    // UI Open/Close functions update state, toggle display and no-scroll.
    menuOpen() {
        this.menuState.menu.open = true;
        this.$mobileMenu.addClass(this.mobileMenuActiveClass);

        this.noScrollToggle(true);
    }

    menuClose() {
        this.menuState.menu.open = false;
        this.$mobileMenu.removeClass(this.mobileMenuActiveClass);

        this.noScrollToggle(false);
    }

    searchOpen() {
        this.searchState.menu.open = true;
        this.$searchPanel.addClass(this.searchPanelActiveClass);
        this.$searchPanelInput.focus();

        this.noScrollToggle(true, this.$searchPanel);
    }

    searchClose() {
        this.searchState.menu.open = false;

        // Close search panel and overlay
        this.$searchPanel.removeClass(this.searchPanelActiveClass);
        this.noScrollToggle(false, this.$searchPanel);
    }

    applyOverlayOpen() {
        this.overlayState.menu.open = true;
        this.linksToggle(true);

        this.$applyOverlay.addClass(this.applyOverlayActiveClass);
        this.noScrollToggle(true, this.$applyOverlay);
    }

    applyOverlayClose() {
        this.overlayState.menu.open = false;
        this.linksToggle(false);

        this.$applyOverlay.removeClass(this.applyOverlayActiveClass);
        this.noScrollToggle(false, this.$applyOverlay);
    }

    // Reset all overlays, menus, and icons
    resetAll(){
        this.applyOverlayClose();
        this.searchClose();
        this.menuClose();
        this.activeToggle(this.$menuTrigger, false);
        this.activeToggle(this.$searchTrigger, false);
        this.ctaToggle(MOBILE, HIDE);
        this.ctaToggle(DESKTOP, SHOW);
        this.visibilityToggle(this.$searchTrigger, true);
    }

    // On click handlers for buttons

    // Mobile Menu Button
    menuToggle() {
        // Clsoe menu if open
        if (this.menuState.menu.open) {
            this.menuClose();
            this.activeToggle(this.$menuTrigger, false);
            this.ctaToggle(MOBILE, HIDE);
            this.visibilityToggle(this.$searchTrigger, true);
        // In Mobile, if apply overlay or menu is open, this closes the apply menu.
        } else if (this.overlayState.menu.open) {
            this.applyOverlayClose();
            this.activeToggle(this.$menuTrigger, false);
            this.ctaToggle(MOBILE, HIDE);
            this.visibilityToggle(this.$searchTrigger, true);
        // If nothing is open, just open menu.
        } else {
            this.menuOpen();
            this.activeToggle(this.$menuTrigger, true);
            this.ctaToggle(MOBILE, SHOW);

            if (this.searchState.menu.open) {
                this.activeToggle(this.$searchTrigger, false);
                this.searchClose();
            }
        }
    }

    // Search Button (both mobile and desktop)
    searchToggle() {
        // Close Search if open
        if (this.searchState.menu.open) {
            this.searchClose();
            this.activeToggle(this.$searchTrigger, false);
            this.ctaToggle(DESKTOP, SHOW);
        // In Mobile, if apply overlay or menu is open, close and then open search
        } else if (this.$menuTrigger.hasClass(this.iconActiveClass)) {
            this.applyOverlayClose(MOBILE);
            this.menuClose();
            this.ctaToggle(MOBILE, HIDE);
            this.activeToggle(this.$menuTrigger, false);
            this.activeToggle(this.$searchTrigger, true);
            this.searchOpen();
        // In desktop, this button ONLY closes the overlay, doesn't open search
        } else if (this.overlayState.menu.open) {
            this.applyOverlayClose(DESKTOP);
            this.activeToggle(this.$searchTrigger, false);
            this.ctaToggle(DESKTOP, SHOW);
        // If nothing else is open, just open search.
        } else {
            this.searchOpen();
            this.ctaToggle(DESKTOP, HIDE);
            this.ctaToggle(MOBILE, HIDE);
            this.activeToggle(this.$searchTrigger, true);
            if (this.menuState.menu.open) {
                this.menuClose();
                this.activeToggle(this.$menuTrigger, false);
            }
        }
    }

    // Apply overlay
    overlayToggle(view) {
        this.applyOverlayOpen();
        // In desktop view, the search icon closes the overlay.
        if (view == DESKTOP) {
            this.activeToggle(this.$searchTrigger, true);
            this.searchClose();
            this.ctaToggle(DESKTOP, HIDE);
        // In mobile view, the menu icon closes the overlay.
        } else if (view == MOBILE) {
            this.activeToggle(this.$menuTrigger, true);
            this.visibilityToggle(this.$searchTrigger, false);
            this.menuClose();
            this.ctaToggle(MOBILE, HIDE);
        }
    }

    bindEvents() {
        this.$searchTrigger.on('click', (e) => {
            e.preventDefault();
            $(window).trigger('headerUI:SearchToggle:toggle');
        });

        this.$menuTrigger.on('click', (e) => {
            e.preventDefault();
            $(window).trigger('headerUI:MobileMenu:toggle');
        });

        this.$overlayTriggerDesktop.on('click', (e) => {
            e.preventDefault();
            $(window).trigger('headerUI:ApplyOverlayDesktop:toggle');
        });

        this.$overlayTriggerMobile.on('click', (e) => {
            e.preventDefault();
            $(window).trigger('headerUI:ApplyOverlayMobile:toggle');
        });

        $(window).on('headerUI:MobileMenu:toggle', () => {
            this.menuToggle();
        });

        $(window).on('headerUI:SearchToggle:toggle', () => {
            this.searchToggle();
        });

        $(window).on('headerUI:ApplyOverlayDesktop:toggle', () => {
            this.overlayToggle(DESKTOP);
        });

        $(window).on('headerUI:ApplyOverlayMobile:toggle', () => {
            this.overlayToggle(MOBILE);
        });

        $(window).on('resize', () => {
            this.resetAll();
        });

        if (!this.isHomePage) {
            $(document).on(
                'scroll',
                throttle(() => {
                    if ($(document).scrollTop() >= this.scrollAmount) {
                        this.$headerCTA.css('top', '');
                        this.$headerCTA.addClass(this.headerCTAfixedClass);
                    } else {
                        this.$headerCTA.css('top', this.headerHeight);
                        this.$headerCTA.removeClass(this.headerCTAfixedClass);
                    }
                }, 150)
            );
        }
    }
}

$('body').each(function () {
    new HeaderUI(this);
});

export default HeaderUI;
