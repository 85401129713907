import $ from 'jquery';
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';

class InfoNav {
    constructor(node) {
        // Button trigger
        this.$node = $(node);

        // Nav
        this.$nav = $('.page-nav__items');

        // Classes
        this.activeClass = 'is-open';

        this.bindEvents();
    }

    bindEvents() {
        this.$node.on('click', e => {
            e.preventDefault();
            this.toggleMenu();
        });
    }

    toggleMenu() {
        this.$nav.hasClass(this.activeClass) ? this.menuClose() : this.menuOpen();
    }

    menuOpen() {
        disableBodyScroll(this.$nav);
        this.$nav.addClass(this.activeClass);
    }

    menuClose() {
        enableBodyScroll(this.$nav);
        this.$nav.removeClass(this.activeClass);
    }
}

$('.js-page-nav-mobile').each(function() {
    new InfoNav(this);
});

export default InfoNav;
