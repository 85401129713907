import $ from 'jquery';

export default () => {
    if (localStorage.getItem('cookieSeen') != 'shown') {
        $('.cookie-notification').delay(2000).fadeIn();
    }
    $('.cookie-notification__button').click(function () {
        $('.cookie-notification').fadeOut();
        localStorage.setItem('cookieSeen', 'shown');
    });
};
