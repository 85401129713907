import $ from 'jquery';

class ClearForm {
    constructor(node) {
        this.$node = $(node);
        this.$input = $('.js-search-input');
        this.$clearInput = $('.js-clear-input');
        this.allSearchInputs = Array.from(this.$input);
        this.allClearButtons = Array.from(this.$clearInput);
        this.bindEvents();
    }

    checkInputContent(e) {
        if(e) {
            if($(e.target).val() === '') {
                $(e.target).next('.js-clear-input').hide();
            } else {
                $(e.target).next('.js-clear-input').show();
            }
        } else {
            this.allSearchInputs.forEach((input) => {
                if (input.value === '') {
                    $(input).next('.js-clear-input').hide();
                } else {
                    $(input).next('.js-clear-input').show();
                }
            });
        }
    }

    clearInputContent() {
        this.$input.val('');
        this.allClearButtons.forEach((button) => {
            $(button).hide();
        });
    }

    bindEvents() {

        // Check input content on load
        $(window).on('load', () => {
            this.checkInputContent();
        });

        // Check input content as user types
        this.$input.on('keyup', (e) => {
            this.checkInputContent(e);
        });

        // Clear input content on button click
        this.$clearInput.on('click', (e) => {
            this.clearInputContent(e);
        });
    }
}

$('body').each(function() {
    new ClearForm(this);
});

export default ClearForm;
