import $ from 'jquery';

export default () => {
    $('.js-table-hint').scroll(function() {
        if ($(this)[0].scrollLeft === 0) {
            $(this).removeClass('is-scrolling');
        } else {
            $(this).addClass('is-scrolling');
        }
    });
};
