import $ from 'jquery';

const link_is_external = a => {
    return a.host == '' || a.host !== window.location.host;
};

export default () => {
    window.addEventListener('load', () => { 
        Array.from(document.links).forEach(link => {
            if (link_is_external(link)) {
                $(link).attr({
                    target: '_blank'
                });
            }
        });
    }, false);
};
