import $ from 'jquery';
import { clearAllBodyScrollLocks } from 'body-scroll-lock';

export default () => {
    $('.page-nav__link').on('click', function(e) {
        // make sure this.hash has a value before overriding default behavior
        if (this.hash !== '') {
            e.preventDefault();

            closeMenu();

            // Allow page scrolling
            clearAllBodyScrollLocks();

            // Store hash
            const hash = this.hash;

            $('html, body').animate({
                scrollTop: $(hash).offset().top
            }, 450, function(){
                // Add has to URL when done scrolling
                window.location.hash = hash;
            });
        } else {
            closeMenu();

            // Allow page scrolling
            clearAllBodyScrollLocks();

            // the top button was clicked
            $('html, body').animate({
                scrollTop: 0
            }, 300);
        }
    });

    function closeMenu() {
        $('.js-page-nav').removeClass('is-open');
    }
};
