import $ from 'jquery';

export default () => {
    $(':not(.person-snippet) + .person-snippet, * > .person-snippet:first-of-type').each(function() {
        $(this).
            nextUntil(':not(.person-snippet)').
            addBack().
            wrapAll('<div class="sf-section sf-section--person-snippet" />');
    });
};
