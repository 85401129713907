import { CountUp } from 'countup.js';
import $ from 'jquery'; 
import { throttle } from 'lodash';

export default () => {
    window.addEventListener(
        'load',
        () => {
            // Function to determine if element is within the window
            const elmInView = (targetId) => {
                let target = $(`#${targetId}`);

                let top_of_element = target.offset().top;
                let bottom_of_element =
                    target.offset().top +
                    target.outerHeight();
                let bottom_of_screen =
                    $(window).scrollTop() + $(window).innerHeight();
                let top_of_screen = $(window).scrollTop();

                return (
                    (bottom_of_screen > top_of_element) && (top_of_screen < bottom_of_element)
                );
            };

            /* BIG NUMBER COUNTUP */
            let targets = document.querySelectorAll('.countup');
            // Only trigger if countup is present
            if (targets) {
                const easingFn = function (t, b, c, d) {
                    let ts = (t /= d) * t;
                    let tc = ts * t;
                    return b + c * (tc + -3 * ts + 3 * t);
                };

                // Create an object to hold all CountUp objects until trigger
                let countUps = {};
                targets.forEach((target) => {
                    let countUp = new CountUp(target.id, target.dataset.count, {
                        duration: 7,
                        easingFn,
                    });
                    countUps[target.id] = {
                        countAnimation: countUp,
                        animationTriggered: false,
                    };

                    // On scroll, determine if one of the CountUp objects is in view. If in view, trigger it.
                    $(window).on('scroll',  throttle(() => {
                        if (
                            elmInView(target.id) &&
                            !countUps[target.id].animationTriggered
                        ) {
                            countUps[target.id].animationTriggered = true;
                            countUps[target.id].countAnimation.start();
                        }
                    }, 150));
                });
            }

            /* ANIMATE FADE IN ON SCROLL */
            let animatedElements = document.querySelectorAll('.aos');

            // Only run if AOS elements are present
            if (animatedElements) {
                let scrollAnimations = {};

                animatedElements.forEach((elm) => {
                    scrollAnimations[elm.id] = {
                        scrollAnimation: elm.dataset.aostype,
                        scrollAnimationTriggered: false,
                    };

                    // On scroll, determine if one of the AOS icons is in view. If in view, trigger it.
                    $(window).on('scroll',  throttle(() => {
                        if (
                            elmInView(elm.id) &&
                            !scrollAnimations[elm.id].scrollAnimationTriggered
                        ) {
                            elm.classList.add(
                                scrollAnimations[elm.id].scrollAnimation
                            );
                            scrollAnimations[
                                elm.id
                            ].scrollAnimationTriggered = true;
                        }
                    }, 150));
                });
            }
        },
        false
    );
};
