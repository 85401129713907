import $ from 'jquery';

export default () => {
    $('.sf-section--person-snippet').each(function() {

        // Check if at least one snippet has an image
        if ($('.person-snippet__image').length) {

            // Find snippets without images and add spacing
            $('.person-snippet').each(function() {
                if (!$(this).has('.person-snippet__image').length) {
                    $(this).addClass('person-snippet--spaced');
                }
            });
        }
    });
};
