import $ from 'jquery';
import headRoom from 'headroom.js';

export default () => {
    if (document.body.classList.contains('template-home-page')) {
        const elem = document.body;

        const $header = $('.header');
        const $headerCTA = $('.header-cta');
        const ctaTop = $header.height();
        const options = {
            offset: ctaTop + 70,
            classes: {
                // when element is initialised
                initial : 'headroom',
                // when scrolling up
                pinned : 'headroom--pinned',
                // when scrolling down
                unpinned : 'headroom--unpinned',
                // when above offset
                top : 'headroom--top',
                // when below offset
                notTop : 'headroom--not-top',
                // when at bottom of scoll area
                bottom : 'headroom--bottom',
                // when not at bottom of scroll area
                notBottom : 'headroom--not-bottom'
            },
            // set the top of the CTA
            onPin: function() {
                $headerCTA.css('top', ctaTop);
            },
            onUnpin: function() {
                $headerCTA.css('top', 0);
            }
        };

        const headroom = new headRoom(elem, options);
        headroom.init();
    }
};

