import $ from 'jquery';

export default () => {
    const allSearchInputs = Array.from(document.querySelectorAll('input[class^="search"]'));
    let urlParams = new URLSearchParams(window.location.search);
    let queryParam = urlParams.get('query');

    // check to see if the url contains a search query
    if (queryParam) {
        allSearchInputs.forEach(input => {
            // handle users coming via the back button
            if (input.value.startsWith('\'')) {
                return;
            }
            // add quotes
            input.value = `'${input.value}'`;
        });
    }

    // strip quotes before searching to prevent mutating the url
    $('form[class^="search"]').submit(function() {
        allSearchInputs.forEach(input => {
            if (input.value.startsWith('\'')) {
                input.value = removeQuotes(input.value);
            }

        });
        return true;
    });

    function removeQuotes(string) {
        return string.replace(/'/g , '');
    }
};
