import $ from 'jquery';


export default () => {
    window.addEventListener('load', () => {
        $('.logo-block__more').click((e) => {
            e.preventDefault();
            $('.logo-block__more').hide();
            $('.logo-block__less').show();
            $('.logo-block-item--hidden').show();
        });

        $('.logo-block__less').click((e) => {
            e.preventDefault();
            $('.logo-block__less').hide();
            $('.logo-block__more').show();
            $('.logo-block-item--hidden').hide();
        });
    }, false);
};
