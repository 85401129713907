import '@babel/polyfill';
import 'whatwg-fetch';
import 'url-search-params-polyfill';

import './components/ClearForm';
import './components/HeaderUI';
import './components/pagenav/PageNavMobile';
import LogoBlock from './components/LogoBlock';
import PageNavDesktop from './components/pagenav/PageNavDesktop';
import PageNavSmoothScroll from './components/pagenav/PageNavSmoothScroll';
import Select from './components/Select';
import HomepageVideo from './components/HomepageVideo';
import StickyHeader from './components/StickyHeader';
import SearchQuotes from './components/SearchQuotes';
import TableScrollHint from './components/TableScrollHint';
import MobileVideo from './components/MobileVideo';
import TopLink from './components/TopLink';
import WrapPersonSnippet from './components/WrapPersonSnippet';
import AlignPersonSnippet from './components/AlignPersonSnippet';
import LazyLoadingInstance from './components/LazyLoading';
import CountUp from './components/CountUp';
import ExternalLinkCheck from './components/ExternalLinkCheck';
import CookieNotification from './components/CookieNotification';

PageNavDesktop();
PageNavSmoothScroll();
Select();
HomepageVideo();
StickyHeader();
SearchQuotes();
TableScrollHint();
MobileVideo();
TopLink();
WrapPersonSnippet();
AlignPersonSnippet();
LazyLoadingInstance();
CountUp();
ExternalLinkCheck();
LogoBlock();
CookieNotification();
